import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import PortableText from '../../serializers/portableText'
import styles from './post.module.css'
import LeftChevron from '../../../images/icons/left-chevron.svg'

const Post = ({_rawBody, postCategory, postDate, teaserImage}) => {
  return (
    <>
      <div className={styles.postWrapper}>

        <p className={styles.postDate}>{postDate}</p>

        <p className={styles.postCategory}>{postCategory}</p>

        {_rawBody && (<div> <PortableText blocks={_rawBody} /> </div>)}

        {/* {teaserImage && (<div className={styles.teaserImage}><Img fluid={teaserImage.asset.fluid} alt={teaserImage.alt} /></div>)} */}

        <p> <Link to={`/news`} className={styles.moreinfoBtn}><span><LeftChevron /> Back to News</span></Link></p>

      </div>
    </>

  )
}
export default Post
