import React from 'react'

import Post from './post'
// import PortableText from '../../serializers/portableText'
import Container from '../../container'
import styles from '../page.module.css'

const JobsPage = ({title, _rawBody, postCategory, postDate, teaserImage}) => {
  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>

          {/* post */}
          <Post
            _rawBody={_rawBody}
            postCategory={postCategory}
            postDate={postDate}
            teaserImage={teaserImage}
          />

        </Container>
      </article>
    </>
  )
}
export default JobsPage
